import { extend, localize } from 'vee-validate'
import {
  required as rule_required,
  email as rule_email,
  min as rule_min,
  confirmed as rule_confirmed,
  regex as rule_regex,
  between as rule_between,
  alpha as rule_alpha,
  integer as rule_integer,
  digits as rule_digits,
  alpha_dash as rule_alpha_dash,
  alpha_num as rule_alpha_num,
  length as rule_length,
} from 'vee-validate/dist/rules'
import ar from 'vee-validate/dist/locale/ar.json'
import en from 'vee-validate/dist/locale/en.json'

// eslint-disable-next-line object-curly-newline
import { validatorPositive, validatorUrlValidator, validatorPassword, validatorCreditCard } from './validators'

// ////////////////////////////////////////////////////////
// General
// ////////////////////////////////////////////////////////

export const required = extend('required', rule_required)

export const email = extend('email', rule_email)

export const min = extend('min', rule_min)

export const confirmed = extend('confirmed', rule_confirmed)

export const regex = extend('regex', rule_regex)

export const between = extend('between', rule_between)

export const alpha = extend('alpha', rule_alpha)

export const integer = extend('integer', rule_integer)

export const digits = extend('digits', rule_digits)

export const alphaDash = extend('alpha-dash', rule_alpha_dash)

export const alphaNum = extend('alpha-num', rule_alpha_num)

export const length = extend('length', rule_length)

export const positive = extend('positive', {
  validate: validatorPositive,
  message: 'Por favor ingresa un número positivo!',
})

export const credit = extend('credit-card', {
  validate: validatorCreditCard,
  message: 'Esta no es una tarjeta de crédito válida!',
})

export const password = extend('password', {
  validate: validatorPassword,
  message: 'Tu {_field_} debe contener al menos una mayúscula, una minúscula, un caracter especial y un número',
})

export const url = extend('url', {
  validate: validatorUrlValidator, 
  message: 'La URL no es válida',
})

// Install English and Arabic localizations.
localize({
  en: {
    messages: en.messages,
    names: {
      Email: 'Email',
      Password: 'Contraseña',
      Tracking : 'Tracking',
      Tienda : 'Tienda',
      Contenido: 'Contenido',
      Destinatario : 'Destinatario',
      Valor_comercial : 'Valor Comercial',
      Valor_asegurado : 'Valor Asegurado',
      Posicion_arancelaria : 'Posición Arancelaria', 
      Orden_de_compra : 'Orden de Compra',
      Nombre_de_la_tienda : 'Nombre de la tienda',
      Tipo_de_identificacion : 'Tipo de Identificación',
      Numero_de_identificacion : 'Número de Identificación',
      Primer_nombre : 'Primer Nombre',
      Primer_apellido : 'Primer Apellido',
      Pais : 'País',
      Departamento: 'Departamento/Estado/Provincia',
      Ciudad : 'Ciudad',
      Barrio : 'Barrio/Colonia',
      Direccion : 'Dirección',
      Telefono_celular : 'Teléfono Celular',
      Medio_de_pago : 'Medio de Pago',
      Forma_de_pago : 'Forma de Pago',
      Tipo_de_Cuenta : 'Tipo de cuenta',
      Razon_social : 'Razón Social',
      Fecha_de_nacimiento : 'Fecha de Nacimiento',
      Codigo_postal : 'Código Postal',
      Confirmar_Email : 'Confirmar Email',
      Confirmar_Password : 'Confirmar Password'
    },
    fields: {
      password: {
        min: 'El campo {_field_} es demasiado corto',
        required : 'El campo {_field_} es obligatorio'
      },
      Tracking : {
        required: 'El campo {_field_} es obligatorio'
      },
      Tienda : {
        required : 'El campo {_field_} es obligatorio'
      },
      Contenido : {
        required : 'El campo {_field_} es obligatorio'
      },
      Destinatario : {
        required : 'El campo {_field_} es obligatorio'
      },
      Valor_comercial : {
        required : 'El campo {_field_} es obligatorio'
      },
      Valor_asegurado : {
        required : 'El campo {_field_} es obligatorio'
      },
      Posicion_arancelaria : {
        required : 'El campo {_field_} es obligatorio'
      },
      Orden_de_compra : {
        required : 'El campo {_field_} es obligatorio'
      },
      Nombre_de_la_tienda : {
        required : 'El campo {_field_} es obligatorio'
      },
      Tipo_de_identificacion : {
        required : 'El campo {_field_} es obligatorio'
      },
      Numero_de_identificacion : {
        required : 'El campo {_field_} es obligatorio'
      },
      Primer_nombre : {
        required : 'El campo {_field_} es obligatorio'
      },
      Primer_apellido : {
        required : 'El campo {_field_} es obligatorio'
      },
      Pais : {
        required : 'El campo {_field_} es obligatorio'
      },
      Departamento : {
        required : 'El campo {_field_} es obligatorio'
      },
      Ciudad : {
        required : 'El campo {_field_} es obligatorio'
      },
      Barrio : {
        required : 'El campo {_field_} es obligatorio'
      },
      Direccion : {
        required : 'El campo {_field_} es obligatorio'
      },
      Email : {
        required : 'El campo {_field_} es obligatorio',
        email: 'El email ingresado es inválido'
      },
      Telefono_celular : {
        required : 'El campo {_field_} es obligatorio',
      },
      Medio_de_pago : {
        required : 'El campo {_field_} es obligatorio',
      },
      Forma_de_pago : {
        required : 'El campo {_field_} es obligatorio',
      },
      Tipo_de_Cuenta : {
        required : 'El campo {_field_} es obligatorio',
      },
      Razon_social : {
        required : 'El campo {_field_} es obligatorio',
      },
      Fecha_de_nacimiento : {
        required : 'El campo {_field_} es obligatorio',
      },
      Codigo_postal : {
        required : 'El campo {_field_} es obligatorio',
      },
      Confirmar_Email : {
        required : 'El campo {_field_} es obligatorio',
      },
      Confirmar_Password : {
        required : 'El campo {_field_} es obligatorio',
      },
    },
  },
  ar: {
    messages: ar.messages,
    names: {
      email: 'البريد الإلكتروني',
      password: 'كلمة السر',
    },
    fields: {
      password: {
        min: 'كلمة السر قصيرة جداً سيتم اختراقك',
      },
    },
  },
})
// ////////////////////////////////////////////////////////
// NOTE:
// Quasar validation for reference only
// Remove this note once development is finished and make sure to
// to convert all of them in veevalidate version
// ////////////////////////////////////////////////////////

// export const required = (val) => {
//   return (val && val.length > 0) || '*Field es obligatorio'
// }

// export const required_obj = (obj) => {
//   if (obj === null || obj === undefined) return '*Field es obligatorio'
//   return (Object.entries(obj).length > 0 && obj.constructor === Object) || '*Field es obligatorio'
// }

// export const no_blank_spaces_arr = (arr) => {
//   return arr.every(val => (val.trim() && val.trim().length > 0)) || 'Los espacios en blanco no están permitidos'
// }

// export const url = val => {
//   // If blank return
//   if (val === undefined || val === null || val.length === 0) return true

//   // Used
//   // https://stackoverflow.com/questions/4314741/url-regex-validation

//   // Other
//   // https://stackoverflow.com/questions/5717093/check-if-a-javascript-string-is-a-url
//   // https://www.w3resource.com/javascript-exercises/javascript-regexp-exercise-9.php
//   // https://www.geeksforgeeks.org/how-to-validate-url-using-regular-expression-in-javascript/

//   /* eslint-disable no-useless-escape */
//   const re = /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/
//   /* eslint-enable no-useless-escape */
//   return re.test(val) || 'URL is invalid'
// }

// export const date = val => {

//   // If blank return
//   if (val === undefined || val === null || val.length === 0) return true

//   // https://github.com/quasarframework/quasar/blob/dev/ui/src/utils/patterns.js
//   return /^-?[\d]+\/[0-1]\d\/[0-3]\d$/.test(val) || 'Date is invalid'
// }

// export const max = (val, max) => {

//   // If blank return
//   if (val === undefined || val === null) return true

//   return val.length <= max || `More than ${max} characters are not allowed`
// }

// export const max_arr = (val, max) => {
//   return val.length <= max || `More than ${max} values are not allowed`
// }

// export const min = (val, min) => {

//   // If blank return
//   if (val === undefined || val === null || val.length === 0) return true

//   return val.length >= min || `Minimum ${min} characters are required`
// }

// export const num_range = (val, min, max) => {

//   // If blank return
//   if (val === undefined || val === null || val.length === 0) return true

//   const msg = 'Value is invalid'
//   if (min === null) return val <= max || msg
//   else if (max === null) return val >= min || msg
//   else return (val >= min && val <= max) || msg
// }
